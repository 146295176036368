@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

* {
    font-family: 'Inter', sans-serif;
}

.absolute {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
}

.card {
    top: 2%;
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    border-radius: 6px;
    padding: 10px;

    width: 450px;

    text-align: center;
    cursor: pointer;

    display: block;
}

.flip {
    top: initial !important;
    bottom: 2%;
}

h1 {
    margin: 0
}

.small {
    font-size: .75rem;
}

.tries {
    font-size: .9rem;
}

.pointName {
    font-size: 1.7rem;
    font-weight: 600;
}

.fixed {
    position: fixed;
    bottom: 0;
    z-index: 1;
}

.medium {
    font-size: 1rem;
}

@media only screen and (max-width: 900px) {
    .card {
        width: 90vw
    }
    /*span {*/
    /*    font-size: 1rem;*/
    /*}*/
    h1 {
        font-size: 1.5rem;
    }

    .medium {
        font-size: 1rem;
    }

    .tries {
        font-size: .85rem;
    }

    .small {
        font-size: .75rem;
    }
}