.pointsCard {
    position: fixed;
    z-index: 10;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #f5f3f3;
    border-radius: 6px;
    padding: 10px;

    width: 400px;
    height: 25vw;

    text-align: center;
    cursor: pointer;
}

.pointsList {
    height: 85%;
}

.scroll {
    height: 87%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    overflow-y: scroll;
}

.pointControl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5%;
}

.pointControlInputs {
    display: flex;
    align-items: center;
}

.controls {
    display: grid;
    /*grid-template-columns: repeat(2, 1fr);*/
    /*grid-template-rows: 1fr;*/
    /*grid-column-gap: 10px;*/
    /*grid-row-gap: 0px;*/
    height: 10%;
}

.listButton {
    width: 100%;
    height: 100%;
}

.pointOnList {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.removeButton {
    width: 30%;
}

.pointsTitle {
    margin-top: 0;
    margin-bottom: 10px;
}

.center {
    text-align: center;
}

.highlight {
    color: red
}

.modalTop {
    top: 2%;
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    border-radius: 6px;
    padding: 10px;

    width: 500px;

    text-align: center;
    cursor: pointer;
    display: block;
}

/*.button {*/
/*  background-color: #639fff;*/
/*  color: #FFF;*/
/*  padding: 10px 25px;*/
/*  border-radius: 8px;*/
/*  border: 0;*/
/*  font-size: .95rem;*/
/*}*/